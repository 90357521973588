<template>
  <div class="about_us">
    <!-- 顶部banner图 -->
    <div class="banner_box">
      <img src="../../../assets/aboutImg/about-ban.jpg" alt="" />
    </div>
    <!-- 公司简介开始 -->

    <div class="introduce">
      <div class="container">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="left_introduce">
              <h2>公司简介</h2>
              <div class="english_introduce">COMPANY PROFILE</div>
              <p>
                望岳科技是一家从事大数据云计算、人工智能、产品开发、咨询服务为一体的综合型互联网科技公司。公司由一批BAT等一线互联网IT精英人士创建，以"快乐工作，认真生活"为愿景，以"科技与应用创新驱动发展"为使命，坚持"客户第一、诚信、激情、拥抱变化"的价值观，打造客户有依靠、员工有干劲、公司有前景的团队组织。
              </p>
            </div>
          </el-col>
          <el-col :span="12">
            <div>
              <img
                class="img-responsive company-profile-img"
                src="../../../assets/aboutImg/about_company.jpg"
                alt=""
              />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 公司简介结束 -->
    <!-- 全新服务开始 -->
    <div class="server_bgn">
      <!-- 遮罩层 -->
      <div class="mask_layer">
        <!-- 版心 -->
        <div class="container">
          <div class="server_text">
            <h4>全新服务体验</h4>
            <p>我们拥有丰富的互联网从业经验，为您提供专业级的技术服务</p>
          </div>
          <el-row :gutter="10">
            <el-col :xs="12" :sm="12" :md="6" class="section_server">
              <div class="server_img_box">
                <img src="../../../assets/aboutImg/web_design.png" alt="" />
              </div>
              <ul>
                <li>网站设计</li>
                <li>Website development</li>
                <li>购物、企业网站开发</li>
              </ul>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" class="section_server">
              <div class="server_img_box">
                <img
                  src="../../../assets/aboutImg/application.png"
                  alt=""
                  class="app_icon"
                />
              </div>
              <ul>
                <li>微信应用</li>
                <li>Wexin application</li>
                <li>H5推广、宣传页、小程序、商城</li>
              </ul> </el-col
            ><el-col :xs="12" :sm="12" :md="6" class="section_server">
              <div class="server_img_box">
                <img src="../../../assets/aboutImg/system.png" alt="" />
              </div>
              <ul>
                <li>管理系统开发</li>
                <li>management</li>
                <li>库存、销售管理、办公系统</li>
              </ul> </el-col
            ><el-col :xs="12" :sm="12" :md="6" class="section_server">
              <div class="server_img_box">
                <img
                  src="../../../assets/aboutImg/app.png"
                  alt=""
                  class="phone_icon"
                />
              </div>
              <ul>
                <li>APP 开发</li>
                <li>App development</li>
                <li>游戏、社交应用、购物支付</li>
              </ul>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 动态背景 -->
    <div class="dynamic_bgn">
      <ul class="container">
        <li>
          我们一直在超越：不断在成长，我们更用心：我们将更好：我们更努力成为第一
        </li>
        <li>
          We’ve been transcending: Growing, We are more mindful: We will be
          better:
        </li>
        <li>We are not the first, So we try harder</li>
      </ul>
    </div>
    <!-- 动态背景结束 -->
    <!-- 联系方式 -->
    <div>
      <div class="container contact_box">
        <div class="contact_text text_center">
          <h4>联系方式</h4>
          <div></div>
          <p>CONTACT US</p>
        </div>
        <el-row :gutter="10" class="text_center">
          <el-col :xs="24" :md="8" :lg="8" >
            <div>
              <div>
                <img src="../../../assets/aboutImg/icon1.png" alt="" />&nbsp;
                <span>手机：186-3390-0235</span>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :md="8" :lg="8">
            <div>
              <div>
                <img src="../../../assets/aboutImg/icon2.png" alt="" />&nbsp;
                <span>QQ： 66970457</span>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :md="8" :lg="8">
            <div>
              <div>
                <img src="../../../assets/aboutImg/icon3.png" alt="" />&nbsp;
                <span>地址：河北省石家庄市雍雅锦江2号商务楼</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 联系我们结束 -->
    <!-- 地理坐标开始 -->
    <div>
      <div class="container">
        <div class="coordinates_text text_center">
          <h4>地理坐标</h4>
          <div></div>
          <p>COORDINATE</p>
        </div>
        <div id="wrapper"></div>
      </div>
    </div>
  </div>
</template>
<script>
import AMap from "AMap"; // 引入高德地图
import {connectUs} from '../../../api/index'
export default {
  name: "aboutUs",
  data() {
    return {
      marker: null,
      map: null,
      infoList:[]
    };
  },
  mounted() {
    this.init();

  },
  created() {
    connectUs().then(res=>{
      console.log(res)
      this.infoList=res
    })
  },
  methods: {
    init() {
      // map 地图
      var position = new AMap.LngLat(114.611587, 38.014053);
      this.map = new AMap.Map("wrapper", {
        // center: [114.6079400338, 38.018790776],
        center: [114.611587,38.014053],
        resizeEnable: true,
        content: "河北省石家庄市雍雅锦江2号商务楼",
        zoom: 14,
        zooms: [3, 16],
      });


        var markerContent = document.createElement("div")
        markerContent.innerHTML = '<div class="custom-content-marker">' +
        "   河北省石家庄市雍雅锦江2号商务楼" +
        '   <div class="close-btn" @click="clearMarker" ref="clickDisappear" id="myMark">X</div>' +
        "</div>" +
        '<div class="amap-info-sharp"><div>'


      this.marker = new AMap.Marker({
        position: position,
        // 将 html 传给 content
        content: markerContent,
        // 以 icon 的 [center bottom] 为原点
        offset: new AMap.Pixel(-13, -30),
      });

      // 将 markers 添加到地图
      this.map.add(this.marker);
      function clearMarker() {
        this.map.remove(this.marker);
      }
      let i = 1;
      if (i == 0) {
        clearMarker();
      }

    markerContent.onclick =  ()=> {
      this.map.remove(this.marker);
    };



    },
    clearMarker() {
      this.map.remove(this.marker);

    },
  },
};
</script>
<style scoped lang='less'>
.banner_box {
  img {
    width: 100%;
  }
}

// 公司简介
.introduce {
  margin: 40px 0;
}
.left_introduce {
  h2 {
    width: 100px;
    font-size: 24px;
    padding: 10px 0;
    font-weight: 700;
    border-bottom: 1px solid #666;
  }
  p {
    line-height: 28px;
    font-size: 15px;
    font-weight: 700;
    width: 80%;
  }
}
.english_introduce {
  padding-top: 10px;
  padding-bottom: 20px;
}
// 公司简介结束
// 全新服务开始
.server_bgn {
  height: 352px;
  background: url(../../../assets/aboutImg/service_bg.jpg) fixed;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
.mask_layer {
  background: rgba(0, 0, 0, 0.1);
  height: 352px;
}
.server_text {
  text-align: center;
  color: #fff;
  font-size: 14px;

  h4 {
    margin: auto;
    padding: 20px 0 10px;
    width: 85px;
    border-bottom: 2px solid #fff;
    font-family: 微软雅黑;
    font-weight: 500;
  }
  p {
    margin-top: 10px;
  }
}
.server_img_box {
  position: relative;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  border-radius: 50%;
  background: #323f48;
  padding: 15px;
  &:hover {
    background-color: #007fff;
  }
  &:hover::after {
    position: absolute;
    left: -5px;
    top: -5px;
    content: "";
    display: block;
    width: 106px;
    height: 106px;
    border: 2px solid #007fff;
    border-radius: 50%;
  }
  &:hover img {
    transform: scale(1.1);
  }
  img {
    width: 35px;
    height: 35px;
    margin: 18px;
    border: none;
  }
  .app_icon {
    width: 27px;
    height: 40px;
    margin: 15px 0 0 23px;
  }
  .phone_icon {
    margin: 17px 0 0 23px;
  }
}
.section_server {
  margin-top: 40px;
  ul {
    color: #fff;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    li {
      padding: 2px 0;
    }
  }
}
// 全新服务结束
// 动态模块开始
.dynamic_bgn {
  color: #fff;
  font-size: 16px;
  height: 250px;
  background: url(../../../assets/aboutImg/about_bottom_banner.gif);
  background-position: 50%;
  background-size: cover;
  ul {
    padding-top: 50px;
    li {
      text-align: center;
      padding: 10px 0;
    }
  }
}
// 动态模块结束
.contact_text {
  padding-top: 30px;
  h4 {
    margin: 0;
    padding: 0;
    font-family: 微软雅黑;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 0;
  }
  div {
    margin: auto;
    background: #000;
    height: 1px;
    width: 70px;
  }
  p {
    font-weight: 400;
    padding: 10px 0;
  }
}

.contact_box {
  padding-bottom: 20px;
  border-bottom: 1px dashed #ccc;
}
// 动态模块结束
// 地理坐标开始
.coordinates_text {
  padding-top: 30px;
  h4 {
    margin: 0;
    padding: 0;
    font-family: 微软雅黑;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 0;
  }
  div {
    margin: auto;
    background: #000;
    height: 1px;
    width: 70px;
  }
  p {
    font-weight: 400;
    padding: 10px 0;
  }
}
#wrapper {
  width: 100%;
  height: 379px;
  margin: 30px 0;
}
&:deep(.custom-content-marker) {
  position: relative;
  left: -80px;
  width: 180px;
  height: 30px;
  background-color: white;
  display: flex;
  align-items: center;
  color: #333333;
  font-weight: 600;
  font-size: 15px;
}

&:deep(.custom-content-marker .close-btn) {
  position: absolute;
  top: -6px;
  right: -8px;
  width: 15px;
  height: 15px;
  font-size: 12px;
  background: #ccc;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 15px;
  box-shadow: -1px 1px 1px rgba(10, 10, 10, 0.2);
}

&:deep(.custom-content-marker .close-btn:hover) {
  background: #666;
}

&:deep(.amap-info-sharp) {
  bottom: -8px;
  left: 10%;
  margin-left: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
}

&:deep(.amap-info-sharp:after) {
  position: absolute;
  content: "";
  margin-left: -8px;
  margin-top: -7px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.3);
  filter: blur(2px);
  z-index: -1;
}
// 地理坐标
</style>
